import _isobject from "isobject";
import _hasValue from "has-value";
var exports = {};
var isObject = _isobject;
var has = _hasValue;

exports = function unset(obj, prop) {
  if (!isObject(obj)) {
    throw new TypeError("expected an object.");
  }

  if (obj.hasOwnProperty(prop)) {
    delete obj[prop];
    return true;
  }

  if (has(obj, prop)) {
    var segs = prop.split(".");
    var last = segs.pop();

    while (segs.length && segs[segs.length - 1].slice(-1) === "\\") {
      last = segs.pop().slice(0, -1) + "." + last;
    }

    while (segs.length) obj = obj[prop = segs.shift()];

    return delete obj[last];
  }

  return true;
};

export default exports;